import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClaimPoints } from '../interface/claim-points.interface';
import { Response } from '@models/response';

@Injectable({
  providedIn: 'root'
})
export class ClaimPointsService {

  constructor(
    private http: HttpClient
  ) { }

  unclaimedPoints(code: string): Observable<Response<ClaimPoints>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams()
      .set('id', code)

    return this.http.get<Response<ClaimPoints>>(
      `/api-loyalty/unclaimedPoints`,
      {
        headers,
        params
      }
    )
  }

  claimUnclaimed(code: string): Observable<Response<ClaimPoints>> {
    return this.http.post<Response<ClaimPoints>>(
      `/api-loyalty/member/point/claim?id=${code}`,
      null
    )
  }

}
