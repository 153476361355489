<div class="modal-wrapper" *ngIf="isShowModal">
  <div class="modal" id="swipe-container">
    <div class="modal-content" id="swipe-content">
      <div class="modal-content-container">
        <div class="ill-delivery-info">
          <app-il-qrlink-paid *ngIf="ill === 'QR_PAID'"></app-il-qrlink-paid>
          <app-il-qrlink-not-have-service *ngIf="ill === 'QR_NOT_HAVE_SERVICE'"></app-il-qrlink-not-have-service>
          <app-il-qrlink-expired *ngIf="ill === 'QR_EXPIRED'"></app-il-qrlink-expired>
          <app-il-qrlink-payment-on-process *ngIf="ill === 'PAYMENT_ON_PROCESS'"></app-il-qrlink-payment-on-process>
          <app-il-sorry *ngIf="ill === 'SORRY'"></app-il-sorry>
        </div>
        <h2 class="title" cdkDragHandle>{{ title | translate }}</h2>

        <p>{{ desc | translate }}</p>

        <div class="modal-footer-container" [class.flex]="btnActionFlex && btnClose">
            <app-button
              type="button"
              [btnClass]="dataBtnClose && dataBtnClose.style ? dataBtnClose.style : 'btn-secondary'"
              (click)="close.next(false); closeModal()"
              *ngIf="btnClose"
              >{{ (dataBtnClose && dataBtnClose.title ? dataBtnClose.title : 'CLOSE') | translate }}</app-button
            >

            <app-button
              *ngIf="btnActionFlex"
              type="button"
              btnClass="btn-primary"
              (click)="confirm.next(false); closeModal()"
              >{{ 'GO_TO_ORDER' | translate }}</app-button
            >
        </div>
      </div>
    </div>
  </div>

  <div class="modal-overlay"></div>
</div>
