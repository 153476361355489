import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { ViewService } from '@data-access/view/view.service'
import { catchError, map, Observable, of } from 'rxjs'
import { ToastService } from '../data-access/toast/toast.service'
import { StoreInfoResolver } from '../resolver/store.resolver'
import { qrStorageId } from '@models/common'
import { StoreInfo } from '@models/home.model'
import { SsrCookieService } from 'ngx-cookie-service-ssr'

@Injectable()
export class ChooseOrderServiceGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _toastService: ToastService,
    private _viewService: ViewService,
    private _storeInfoResolver: StoreInfoResolver,
    @Inject(PLATFORM_ID) public platformId: string,
    private _ssrCookieService: SsrCookieService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._storeInfoResolver.resolve(route, state).pipe(
      map((response: StoreInfo) => {
        let storeInfo: StoreInfo = response

        let qrStoreageId: qrStorageId = JSON.parse(localStorage.getItem(`${storeInfo.qr}`) ?? '{}')

        let orderService: string = ''
        if (qrStoreageId.hasOwnProperty('serviceType')) orderService = qrStoreageId.serviceType;
        let configService: string[] = [...storeInfo?.serviceType]

        // If order service in storage available in configService
        let orderServiceAvailable: boolean = configService.includes(orderService)
        if (orderServiceAvailable) {
          return true
        }

        // If order service not available in storage and check configService length more than 1
        // then redirect to choose order service
        if (!orderServiceAvailable && configService?.length > 1) {
          if (route.queryParams['qr']) {
            this.router.navigate([`order-service`], {
              queryParams: route.queryParams
            })
          }
          this._toastService.broadcast(
            'SHOW_TOAST',
            'SELECT_ORDER_SERVICE_FIRST'
          )
          return true
        }

        // If Order Service not available in storage and configService length equals is 1
        // and then set storage and redirect to home
        if (!orderServiceAvailable && configService.length === 1) {
          this._viewService.setServiceType(
            configService[0],
            storeInfo?.qr
          )

           // If store have loyalty and member not login yet, please redirect to page login
          if (storeInfo.isUseLoyalty && !this._ssrCookieService.check('member')) {
            this.router.navigate([`/login`], {
              queryParams: route.queryParams
            })
          }
        
          return true
        }

        return false
      }),
      catchError(() => {
        return of(false)
      })
    )
  }
}
