import { isPlatformBrowser } from '@angular/common';
import {  Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AuthMemberService } from '@auth/data-access/auth-member.service';
import { IPhoneNumberCountry } from '@profile/interface/profile.model';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService  {
  public isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();

  constructor(private authMemberService: AuthMemberService, @Inject(PLATFORM_ID) private platformId: string, private _ssrCookieService: SsrCookieService) {}

  get isMemberLoggedIn(): boolean {
    if (this._ssrCookieService.check('member')) {
      this.isLoggedInSubject.next(true); // Update status login
      return true
    } else {
      this.isLoggedInSubject.next(false); // Update status login
      return false
    }
  }

  getCurrentLoginStatus(): boolean {
    return this.isMemberLoggedIn
  }

  async setUserState(data: { phoneCodeList: any } = {} as any) {
    const user = await firstValueFrom(this.authMemberService.getUser()) as any;
   
    if (isPlatformBrowser(this.platformId)) {
      this._ssrCookieService.set('member', JSON.stringify(user.data))
      const phoneCode = user.data.member.phoneNumber.countryCode
      const phoneCountryCode = data.phoneCodeList.find((item: IPhoneNumberCountry) => item.value === phoneCode)!.country ?? 'SG'
      const phoneNumber = user.data.member.phoneNumber.number
      const phoneCodeText = `+${phoneCode ?? ''}`
      
      sessionStorage.setItem(
        'checkoutField',
        JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('checkoutField') || '{}'),
          fullName: user.data.member.name,
          email: user.data.member.email,
          phoneCode: phoneCode,
          phoneCodeText: phoneCodeText,
          phoneNumber: phoneNumber,
          phoneCountryCode: phoneCountryCode,
          coupon: undefined,
          voucher: null
        })
      );
      
    }
  }
}
