import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { ViewService } from '@data-access/view/view.service'
import { StoreInfo } from '@models/home.model'
import { SsrCookieService } from 'ngx-cookie-service-ssr'
import { Observable, map } from 'rxjs'
import { StoreInfoResolver } from '../resolver/store.resolver';
import { CartUtilitiesService } from '@data-access/utilities/cart-utilities.service'

@Injectable()
export class AuthNotVerifyGuard implements CanActivate {
  storeInfo: StoreInfo = new Object() as StoreInfo
  
  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private _cookieService: SsrCookieService,
    private _viewService: ViewService,
    private _storeInfoResolver: StoreInfoResolver,
    private _cartUtilitiesService: CartUtilitiesService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this._storeInfoResolver.resolve(route, state).pipe(
      map((response: StoreInfo) => {
        this.storeInfo = response
        let authToken = this._cookieService.get('authToken');
        let isLoggedIn: boolean = false

        /**
         * If loyalty === true,
         * Localstorage have a member (user) in storage = loggedIn true
         * authToken in storage, then remove authToken
         */
        
        if (this.storeInfo.isUseLoyalty) {
          if (this._cookieService.check('member')) {
            isLoggedIn = true
          } else if (authToken) {
            this._cookieService.delete('authToken');
          }
        } else {
          /**
         * If loyalty === false,
         * authToken in storage, loggedIn = true
         * Localstorage have a member (user) in storage = and then remove
         * 
         */

          if (authToken) {
            isLoggedIn = true
          } else if (this._cookieService.check('member')) {
            this._cookieService.delete('member')
            this._viewService.logout()
            this._cartUtilitiesService.clearCarts(this.storeInfo.qr, this.storeInfo.storeSlug)
          }
        }

        if (!isLoggedIn) return true

        if (route.queryParams['qr']) {
          this.router.navigate([`/profile`], {
            queryParams: route.queryParams
          })
        }
        return false
      })
    )
    
  }
}
