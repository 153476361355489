import { createReducer, on } from '@ngrx/store'
import { CheckoutForm } from 'src/app/checkout/interface/checkout.model'
import {
  ResetCheckout,
  UpdateBulkCheckoutCustomer,
  UpdateCheckout,
  UpdateCheckoutCustomer
} from './checkout-info.actions'

export const initialState: CheckoutForm = {
  deviceId: '',
  serviceType: '',
  paymentMethode: '',
  customer: {
    fullName: '',
    phoneCountryCode: '',
    phoneNumber: '',
    agreePromotion: true
  },
  coupon: [],
  costCartDiscountCut: 0,
  orderAt: 0,
  memberId: 0,
  usePoints: false,
  loyaltyVoucherId: 0
}

const newState = (state: CheckoutForm, newData: CheckoutForm) => {
  return Object.assign({}, state, newData)
}

export const checkoutInfoReducer = createReducer(
  initialState,
  on(UpdateCheckout, (state, { payload }) => newState(state, payload)),
  on(UpdateCheckoutCustomer, (state: any, { customer, withProp }) => {
    if (!withProp) return { ...state, ...state.customer }

    return {
      ...state,
      customer: {
        ...customer,
        [withProp]: customer[withProp]
      }
    }
  }),
  on(UpdateBulkCheckoutCustomer, (state: any, { customer }) => ({
    ...state,
    customer
  })),
  on(ResetCheckout, state => initialState)
)
