export const MerchantSetting = {
  choupinette: {
    merchantId: 1117,
    storeId: 1043,
    storeName: 'Choupinette Singapore',
    customMenu: {
      brunch: 'brunch 8am - 3pm',
      dinner: 'dinner 4pm - 9pm',
      breakfast: 'breakfast'
    }
  },
  yogiyo: {
    prod: {
      name: 'Yogiyo',
      merchantId: 1145,
      storeId: 1071
    },
    uat: {
      name: 'Yogiyo UAT',
      merchantId: 1172
    }
  },
  frog: {
    prod: {
      name: 'frog',
      merchantId: 1150,
      storeId: 1077
    },
    uat: {
      name: 'madim',
      merchantId: 899
    },
    stg: {
      name: 'dogali',
      merchantId: 1091
    }
  },
  gossipBar: {
    prod: {
      name: 'gossipBar',
      merchantId: 1156,
      items: [
        {
          name: 'Guinness Draft',
          id: 2982
        },
        {
          name: 'Guinness pint of 3',
          id: 3051
        }
      ]
    },
    uat: {
      name: 'cihan',
      merchantId: 919,
      items: [
        {
          name: 'Guinness Draft',
          id: 2552
        },
        {
          name: 'Guinness pint of 3',
          id: 2559
        }
      ]
    },
    stg: {
      name: 'banag',
      merchantId: 1113,
      items: [
        {
          name: 'Guinness Draft',
          id: 2383
        },
        {
          name: 'Guinness pint of 3',
          id: 2399
        },
      ]
    }
  },
  sabarMenanti: {
    prod: {
      name: 'sabarMenanti',
      merchantId: 1176
    },
    prodTesting: {
      name: 'cihan',
      merchantId: 919
    },
    uat: {
      name: 'coffefomo',
      merchantId: 953
    }
  },
  chungky: {
    prod: {
      name: 'chungky',
      merchantId: 1163
    },
    uat: {
      name: 'cihan',
      merchantId: 919
    }
  },
  mrEggFiredRice: {
    prod: {
      name: 'Mr Egg fired rice',
      merchantId: 1234
    },
    uat: {
      name: 'Foodies Singapore',
      merchantId: 1171
    }
  },
  ivin: {
    prod: {
      name: 'Ivin',
      merchantId: 1250,
      storeId: 1212
    },
    uat: {
      name: 'Cemal Cemil Rawa Buaya',
      merchantId: 13,
      storeId: 7
    }
  },
  kitchenDelzafFood: {
    prod: {
      name: 'Kitchen Delzaf Food',
      merchantId: 1283
    },
    uat: {
      name: 'cihan',
      merchantId: 13
    }
  },
  charcoalgrillsg: {
    prod: {
      name: 'charcoalgrillsg',
      merchantId: 1275
    },
    uat: {
      name: 'madim',
      merchantId: 899
    }
  },
  bukchonKoreanResto: {
    prod: {
      name: 'Bukchon Korean Restaurant',
      merchantId: 1295
    },
    uat1: {
      name: 'vojis24763',
      merchantId: 1184
    },
    uat2: {
      name: 'Idecafe',
      merchantId: 1118
    },
    uat3: {
      name: 'Bukchon UAT',
      merchantId: 1327
    }
  },
  chowGarrison: {
    prod: {
      name: 'Chow Garrison',
      merchantId: 1321
    }
  }
}