import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { Response } from '@models/response'
import { Observable } from 'rxjs'
import { QRInfo } from '../../models/domain.model'

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string
  ) {}

  getQRInfo(qrCode: string): Observable<Response<QRInfo>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')


    return this.http.get<Response<QRInfo>>(`/api/sell/v3-a/qr/${qrCode}`, {
      headers: headers,
    })
  }
}
