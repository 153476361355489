import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IAuthBySessionId } from '@auth/interface/member.interface';
import { IPasswordResetRequestPayload, IPasswordResetRequestResponse, IResetPassword } from '@auth/interface/reset-password.interface';
import { IOtp, ISignIn } from '@auth/interface/signin.interface';
import { IResendOtp, ISignUp, ISignUpResponse } from '@auth/interface/signup.interface';
import { Response } from '@models/response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthMemberService {

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public readonly platformId: string
  ) {}

  signUp(signupData: ISignUp): Observable<Response<ISignUpResponse>> {
    return this.http.post<Response<ISignUpResponse>>(`/api-membership/auth/signup`, signupData);
  }

  signIn(signInData: ISignIn): Observable<Response<ISignIn>> {
    return this.http.post<Response<ISignIn>>(`/api-membership/auth/login`, signInData);
  }

  getUser(): Observable<Response<IAuthBySessionId>> {
    return this.http.get<Response<IAuthBySessionId>>(`/api-membership/auth`);
  }

  logout(): Observable<Response<[]>> {
    return this.http.post<Response<[]>>(`/api-membership/auth/logout`, null);
  }

  verifySmsOtp(payload: IOtp): Observable<Response<IOtp>> {
    return this.http.post<Response<IOtp>>(`/api-membership/auth/verify/smsOtp`, payload);
  }

  verifyEmail(verificationCode: string): Observable<Response<[]>> {
    return this.http.get<Response<[]>>(`/api-membership/auth/verify`, {
      params: {
        verificationCode
      }
    });
  }

  passwordResetRequest(passwordResetRequest: IPasswordResetRequestPayload): Observable<Response<IPasswordResetRequestResponse>> {
    return this.http.put<Response<IPasswordResetRequestResponse>>(`/api-membership/auth/resetPassword`, passwordResetRequest);
  }

  resetPassword(resetPassword: IResetPassword): Observable<Response<IResetPassword>> {
    return this.http.put<Response<IResetPassword>>(`/api-membership/auth/resetPassword/newPassword`, resetPassword);
  }

  resendOtp(resendOtp: IResendOtp): Observable<Response<IResendOtp>> {
    return this.http.post<Response<IResendOtp>>(`/api-membership/auth/signup/reSendOtp`, resendOtp);
  }
}
